import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ReportFiltersService {
    openFilters$ = new Subject<void>();

    openFilters() {
        this.openFilters$.next();
    }
}
